

































































































































import mixins from 'vue-typed-mixins'
import Booking from '@/calendesk/sections/section/booking/mixins/Booking'
export default mixins(Booking).extend({
  name: 'Booking1'
})
